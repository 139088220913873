import React from 'react'
import Container from '../components/container'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Link } from 'gatsby'

const NotFoundPage = () => (
  <Layout>
    <SEO title='404: Siden ble ikke funnet' />
    <Container>
      <center>
        <h1>Siden ble ikke funnet</h1>
        <p>Vi beklager - siden ble dessverre ikke funnet. Trykk <Link to="/">her</Link> for å komme til forsiden.</p>
      </center>

    </Container>
  </Layout>
)

export default NotFoundPage
